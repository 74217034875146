import React from 'react'
import DesignSystem from '../../components/DesignSystem'

import Islands from './index'

const Lanai = () => {
  return (
    <Islands activeIsland="lanai">
      <DesignSystem.H1>Lanai</DesignSystem.H1>
      <DesignSystem.P>blurb</DesignSystem.P>
    </Islands>
  )
}

export default Lanai
